import projectImg01 from "./../img/projects/01.jpg"
import projectImg02 from "./../img/projects/02.jpg"
import projectImg03 from "./../img/projects/03.jpg"
import projectImg04 from "./../img/projects/04.png"
import projectImg05 from "./../img/projects/05.png"
import projectImg06 from "./../img/projects/06.png"
import projectImg07 from "./../img/projects/07.png"
import projectImg08 from "./../img/projects/08.png"

import projectImgBig01 from "./../img/projects/01-big.jpg"
import projectImgBig02 from "./../img/projects/02-big.jpg"
import projectImgBig03 from "./../img/projects/03-big.jpg"
import projectImgBig04 from "./../img/projects/04-big.png"
import projectImgBig05 from "./../img/projects/05-big.png"
import projectImgBig06 from "./../img/projects/06-big.png"
import projectImgBig07 from "./../img/projects/07-big.png"
import projectImgBig08 from "./../img/projects/08-big.png"

const projects = [
    {
        id: 0,
        title: "Gaming Consoles",
        img: projectImg01,
        imgBig: projectImgBig01,
        gitHub: "https://github.com/GitMaxm/game-gulp",
        gitHubLink: "https://gitmaxm.github.io/game-gulp/",
        topic: "Игровые консоли",
        description: "Сайт, посвящённый игровым консолям. Одностраничный сайт с мобильной адаптацией, выполненный в учебных целях по готовому макету."
    },
    {
        id: 1,
        title: "Freedom studios academy",
        img: projectImg02,
        imgBig: projectImgBig02,
        gitHub: "https://github.com/GitMaxm/studio-gulp",
        gitHubLink: "https://gitmaxm.github.io/studio-gulp/",
        topic: "Академия студии Freedom",
        description: "Одностраничный сайт академии студии Freedom с мобильной адаптацией, выполненный в учебных целях по готовому макету."
    },
    {
        id: 2,
        title: "Реплики картин",
        img: projectImg03,
        imgBig: projectImgBig03,
        gitHub: "https://github.com/GitMaxm/Paints",
        gitHubLink: "https://gitmaxm.github.io/Paints/",
        topic: "Реплики картин от Ink. House",
        description: "Одностраничный сайт, посвящённый репликам картин от Ink. House, с мобильной адаптацией, выполненный в учебных целях по готовому макету."
    },
    {
        id: 3,
        title: "Мебель от Лёли",
        img: projectImg04,
        imgBig: projectImgBig04,
        gitHub: "https://github.com/GitMaxm/mlt-mebel-scss",
        gitHubLink: "http://mlt-mebel.ru/",
        topic: "Мебель от Лёли",
        description: "Одностраничный сайт, посвящённый изготовлению мебели на заказ. Сайт был выполнен по заказу клиента."
    },
    {
        id: 4,
        title: "Клуб четырех коней",
        img: projectImg05,
        imgBig: projectImgBig05,
        gitHub: "https://github.com/GitMaxm/four-horse-club-scss",
        gitHubLink: "https://gitmaxm.github.io/four-horse-club-scss/",
        topic: "Клуб четырёх коней",
        description: "Техническое задание для устройства на должность верстальщика (Yandex). Одностраничный сайт турнира по шахматам с мобильной адаптацией. На сайте присутствует несколько слайдеров."
    },
    {
        id: 5,
        title: "СРА сеть SHAKES PRO",
        img: projectImg06,
        imgBig: projectImgBig06,
        gitHub: "https://github.com/GitMaxm/shakes-pro-scss",
        gitHubLink: "https://gitmaxm.github.io/shakes-pro-scss/",
        topic: "СРА сеть SHAKES PRO",
        description: "Техническое задание для устройства на должность верстальщика (DatsTeam). Одностраничный сайт с мобильной адаптацией, посвящённый СРА сети SHAKES PRO."
    },
    {
        id: 6,
        title: "ООО \"Авангард\"",
        img: projectImg07,
        imgBig: projectImgBig07,
        gitHub: "https://github.com/GitMaxm/litplastmas-scss.git",
        gitHubLink: "https://litplastmas.ru/",
        topic: "ООО \"Авангард\"",
        description: "Сайт-визитка для компании ООО \"Авангард\", занимающейся производством изделий из пластмассы. Одностраничный сайт с мобильной адаптацией, выполненный по заказу клиента."
    },
    {
        id: 7,
        title: "Цифровое агенство \"BINO\"",
        img: projectImg08,
        imgBig: projectImgBig08,
        gitHub: "https://github.com/GitMaxm/bino-scss",
        gitHubLink: "https://gitmaxm.github.io/bino-scss/",
        topic: "Цифровое агентство \"BINO\"",
        description: "Сайт-визитка для цифрового агентства \"BINO\". Одностраничный сайт с мобильной адаптацией, выполненный по макету в учебных целях."
    },
]

export default projects