
import React, { useState } from 'react';
import Modal from '../modal/Modal';

const ModalButton = ({ 
  buttonText = "Связаться со мной",
  buttonClassName = "btn btn-modal"
}) => {
  const [modalActive, setModalActive] = useState(false);

  return (
    <>
      <button 
        className={buttonClassName} 
        onClick={() => setModalActive(true)}
      >
        {buttonText}
      </button>
      
      <Modal 
        active={modalActive} 
        setActive={setModalActive}
      />
    </>
  );
};

export default ModalButton;