import Header from '../components/header/Header';
import { NavLink } from "react-router-dom";

import advantages01 from './../img/advantages/01.png'
import advantages02 from './../img/advantages/02.png'
import advantages03 from './../img/advantages/03.png'
import advantages04 from './../img/advantages/04.png'
import Stage from '../components/stage/Stage';
import About from '../components/about/About';
import ModalButton from '../components/modal/ModalButton';

const Home = () => {


    return (
        <>
            <Header />

            <main className="section">

                <div className="container">

                    <About />

                    <div className="advantages">
                        <h2 className="title-1 title-1--home">Преимущества работы со мной</h2>
                        <ul className="advantages-list">
                            <li>
                                <img src={advantages01} alt="Преимущества работы" />
                                <div>
                                    <h3>Индивидуальный подход</h3>
                                    <p>Индивидуальный подход к каждому проекту. Для меня это не просто работа, а возможность творческого и профессионального роста.</p>
                                </div>
                            </li>
                            <li>
                                <img src={advantages02} alt="Преимущества работы" />

                                <div>
                                    <h3>Доступные цены</h3>
                                    <p>Доступные цены при высоком качестве работы. Я предлагаю одни из лучших расценок на рынке.</p>
                                </div>
                            </li>
                            <li>
                                <img src={advantages03} alt="Преимущества работы" />

                                <div>
                                    <h3>Оперативность</h3>
                                    <p>Благодаря большому опыту я могу выполнять заказы в сжатые сроки.</p>
                                </div>
                            </li>
                            <li>
                                <img src={advantages04} alt="Преимущества работы" />
                                <div>
                                    <h3>Постоянная поддержка</h3>
                                    <p>После завершения проекта я продолжу заботиться о вашем сайте: хостинг, обновления, техническая поддержка.</p>
                                </div>

                            </li>
                        </ul>
                    </div>

                    <div className="services">
                        <h2 className="title-1 title-1--home">Услуги</h2>
                        <div className="services-text">
                            <p> Создаю лендинги с нуля или верстаю по вашим готовым макетам. Если у вас нет макета — предложу решение, исходя из ваших пожеланий.</p>
                            <p>Также предлагаю полностью адаптировать свои <NavLink to={`/projects`}>готовые проекты</NavLink> под ваш продукт. Это не только сократит сроки разработки, но и поможет сэкономить бюджет, сохраняя высокое качество.
                                Быстрая и эффективная адаптация под ваши потребности — идеальное решение для запуска успешного проекта.</p>
                        </div>

                        <ul className='services-list'>

                            <li>
                                <div >
                                    <svg width={50} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                        <rect x="3" y="3" width="18" height="18" rx="2" />
                                        <path d="M3 9h18" />
                                    </svg>
                                </div>
                                <h3>Адаптивный сайт</h3>
                                <p>Создание полностью отзывчивых сайтов для всех устройств</p>
                            </li>

                            <li>
                                <div >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                        <rect x="2" y="2" width="20" height="8" rx="2" />
                                        <rect x="2" y="14" width="20" height="8" rx="2" />
                                    </svg>
                                </div>
                                <h3>Сопровождение сайта</h3>
                                <p>Техническая поддержка и обновление контента</p>
                            </li>

                            <li>
                                <div >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                        <rect x="3" y="3" width="18" height="18" rx="2" />
                                        <path d="M3 9h18" />
                                    </svg>
                                </div>
                                <h3>Макет и дизайн</h3>
                                <p>Разработка уникального дизайна под ваши потребности</p>
                            </li>

                            <li>
                                <div >
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                        <polyline points="16 18 22 12 16 6" />
                                        <polyline points="8 6 2 12 8 18" />
                                    </svg>
                                </div>
                                <h3>Установка на хостинг</h3>
                                <p>Размещение сайта и настройка домена</p>
                            </li>


                        </ul>

                        <div className='services-more'>
                            <NavLink to={`/price`}>
                                Подробнее
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-4 h-4"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path d="M5 12h14" />
                                    <path d="M12 5l7 7-7 7" />
                                </svg></NavLink>
                        </div>

                    </div>

                    <Stage />

                    {/* Сделать фиксированную кнопку связи */}
                    {/* Добавить FAQ для снятия возражений перед финальным CTA */}

                    <div className="btnForm">
                        <ModalButton />
                    </div>

                    {/* <ul className="content-list">
                        <li className="content-list__item">
                            <h2 className="title-2">Навыки</h2>
                            <p>HTML, CSS, JavaScript</p>
                        </li>
                        <li className="content-list__item">
                            <h2 className="title-2">Инструменты</h2>
                            <p>Gulp, SCSS, Webpack</p>
                        </li>
                    </ul> */}

                </div>
            </main >
        </>
    );
}

export default Home;