import './style.css'

const PriceTable = () => {
    return (
        <div>

            <div className="price__column" id="generic_price_table">

                {/* есть макет  */}
                <section className="section__price">

                    <div className="row">
                        <div className="col-md-4">

                            <div className="generic_content clearfix">

                                <div className="generic_head_price clearfix">

                                    <div className="generic_head_content clearfix">

                                        <div className="head_bg"></div>
                                        <div className="head">
                                            <span>У вас <b>есть</b> макет</span>
                                        </div>

                                    </div>

                                    <div className="generic_price_tag clearfix">
                                        <span className="price">

                                            <span className="price">
                                                <span className="cent">от </span>
                                                <span className="currency">10000</span>
                                                <span className="cent">рублей</span>
                                            </span>
                                        </span>
                                    </div>

                                </div>

                                <div className="generic_feature_list">
                                    <ul>
                                        <li><span className="price__list-title">Адаптивный дизайн</span>
                                            <br /><br />Ваш сайт будет удобен и функционален на всех типах устройств — от мобильных телефонов до настольных компьютеров.</li>

                                        <li><span className="price__list-title">Верстка по макету</span>
                                            <br /><br />Точно следую вашему дизайну, создаю сайт по готовому макету и адаптирую его для всех устройств с учетом всех технических требований.</li>

                                        <li><span className="price__list-title">Кроссбраузерная совместимость</span>
                                            <br /><br />Гарантирую корректную работу сайта во всех популярных браузерах, чтобы каждый пользователь имел одинаковый опыт, независимо от используемой платформы.</li>

                                        <li><span className="price__list-title">Оптимизация скорости загрузки</span>
                                            <br /><br />Оптимизирую код и изображения для быстрой загрузки страниц, что положительно влияет на пользовательский опыт и SEO.</li>

                                        <li><span className="price__list-title">Интерактивные элементы</span>
                                            <br /><br />Реализую анимации и интерактивные элементы, чтобы сайт выглядел современно и привлекательно, сохраняя при этом высокую производительность.</li>

                                        <li><span className="price__list-title">Поддержка после запуска</span>
                                            <br /><br />Предоставляю бесплатное сопровождение в течение 2 месяцев после завершения разработки, с возможностью внесения изменений и доработок по вашему запросу.</li>
                                    </ul>
                                </div>


                            </div>
                        </div>

                    </div>

                </section>

                {/* нет макета */}
                <section className="section__price">

                    <div className="row">
                        <div className="col-md-4">

                            <div className="generic_content clearfix">

                                <div className="generic_head_price clearfix">

                                    <div className="generic_head_content clearfix">

                                        <div className="head_bg"></div>
                                        <div className="head">
                                            <span>У вас <b>нет</b> макета</span>
                                        </div>

                                    </div>

                                    <div className="generic_price_tag clearfix">
                                        <span className="price">

                                            <span className="price">
                                                <span className="cent">от</span>
                                                <span className="currency">6000</span>
                                                <span className="cent">рублей</span>
                                            </span>
                                        </span>
                                    </div>

                                </div>

                                <div className="generic_feature_list">
                                    <ul>
                                        <li><span className="price__list-title">Адаптивный дизайн</span>
                                            <br /><br />Ваш сайт будет идеально отображаться на всех устройствах — от смартфонов до компьютеров.</li>

                                        <li><span className="price__list-title">Удобная структура</span>
                                            <br /><br /><b>Три основных секции</b>: шапка, основной блок с контентом и подвал. Простой и логичный подход к организации информации.</li>

                                        <li><span className="price__list-title">Интуитивная навигация</span>
                                            <br /><br />Легкое и понятное меню для пользователей. Включает всплывающее меню для удобного использования на мобильных устройствах.</li>

                                        <li><span className="price__list-title">Количество страниц</span>
                                            <br /><br />Одностраничный сайт, который объединяет все необходимые элементы на одной странице.</li>

                                        <li><span className="price__list-title">SEO-оптимизация</span>
                                            <br /><br />Верстка с учетом современных требований SEO для лучшей индексации сайта и повышения его видимости в поисковых системах.</li>

                                        <li><span className="price__list-title">Поддержка и сопровождение</span>
                                            <br /><br />Бесплатное сопровождение в течение 2 месяцев после завершения проекта, включая внесение изменений и доработок по запросу.</li>

                                        <li><span className="price__list-title">Готовый макет и дизайн</span>
                                            <br /><br />Использую готовые кейсы и адаптирую их под ваши задачи для достижения лучшего результата.</li>
                                    </ul>
                                </div>




                            </div>
                        </div>

                    </div>

                </section>

                {/* допы */}
                <section className="section__price">

                    <div className="row">
                        <div className="col-md-4">

                            <div className="generic_content clearfix">

                                <div className="generic_head_price clearfix">

                                    <div className="generic_head_content clearfix">

                                        <div className="head_bg"></div>
                                        <div className="head">
                                            <span>Дополнительные услуги</span>
                                        </div>

                                    </div>

                                    <div className="generic_price_tag clearfix">
                                        <span className="price">

                                            <span className="price">
                                                <span className="cent">Не входит в стоимость основных услуг</span>
                                            </span>
                                        </span>
                                    </div>

                                </div>

                                <div className="generic_feature_list">
                                    <ul>

                                        <li><span className="price__list-title">Установка на хостинг и регистрация доменного имени</span>:
                                            <br /><span className="price__main">от 2000 руб.</span>
                                            <br /><br />Установка сайта на мой хостинг сроком на <b>1 год</b>. Также помогу с регистрацией доменного имени.</li>

                                        <li><span className="price__list-title">Добавление дополнительных блоков</span>:
                                            <br /><span className="price__main">от 500 руб.</span>
                                            <br /><br />Добавление новых блоков на страницу, таких как формы, галереи, контактные данные и другие элементы по вашему запросу.</li>

                                        <li><span className="price__list-title">Анимированный слайдер</span>:
                                            <br /><span className="price__main">от 300 руб.</span>
                                            <br /><br />Создание красивого анимированного слайдера для визуального улучшения сайта и повышения интерактивности.</li>

                                        <li><span className="price__list-title">Дополнительные страницы</span>:
                                            <br /><span className="price__main">1000 руб.</span>
                                            <br /><br />Создание дополнительных страниц, например, для услуг, портфолио, блога или контактной информации.</li>

                                        <li><span className="price__list-title">Темная тема</span>:
                                            <br /><span className="price__main">1000 руб.</span>
                                            <br /><br />Реализую кнопку переключения на темную тему для создания элегантного и стильного визуального оформления сайта.</li>

                                        <li><span className="price__list-title">Сопровождение сайта</span>:
                                            <br /><span className="price__main">500 руб./мес</span>
                                            <br /><br />Внесение изменений, доработки и техническая поддержка сайта в процессе его использования.</li>

                                    </ul>
                                </div>



                            </div>
                        </div>

                    </div>

                </section>
            </div>
        </div>
    );
}

export default PriceTable;