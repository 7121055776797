
import './about.css';
import me from './../../img/me.jpg'

const About = () => {


    return (
        <div className="about">

            <h1 className="title-1 title-1--home">Обо мне</h1>

            <div className='about-box'>
                <img src={me} alt="мое фото" className="about-img" />

                <div className="about-text">
                    <p>
                        Я опытный верстальщик, специализирующийся на создании интерактивных и привлекательных веб-сайтов. В настоящее время работаю в компании "DatsTeam", которая занимается разработкой мобильных приложений и платформ для рекламы и игр.
                    </p>

                    <p>
                        Мой профессиональный опыт позволяет разрабатывать уникальные, функциональные и удобные для пользователей сайты, которые полностью отвечают современным стандартам веб-разработки и требованиям клиентов. Я стремлюсь к созданию эффективных решений для каждого проекта, обеспечивая высокое качество и удовлетворенность заказчиков.
                    </p>

                </div>
            </div>


            {/* <div className="about-text">
            <p> Я верстальщик с опытом работы, специализирующийся на создании интерактивных и привлекательных сайтов. Начав с базовых знаний HTML, CSS и JavaScript, я постоянно развиваю свои навыки, используя современные технологии, такие как Gulp и SCSS. Это позволяет мне автоматизировать рутинные задачи и повышать эффективность.
            </p>

            <p>На текущий момент являюсь верстальщиком в компании <b>"DatsTeam"</b>, где работаю в одном из проектов. Мой опыт позволяет мне создавать уникальные, функциональные и удобные для пользователей веб-сайты, полностью отвечающие требованиям клиентов и соответствующие современным стандартам веб-разработки. Я стремлюсь обеспечить наилучший результат для каждого проекта.
            </p>

            <p>Моя цель - воплотить ваши идеи, создав веб-сайт, который будет заметным, успешным и превзойдет ваши ожидания. Если вы ищете профессионала в области верстки, готового предоставить качественный результат, обращайтесь ко мне. Вместе мы создадим нечто удивительное!</p>
        </div> */}
        </div>


    );
}

export default About;