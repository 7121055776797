import vk from './../../img/icons/vk.svg'
import telegram from './../../img/icons/telegram.svg'
import instagram from './../../img/icons/instagram.svg'
import gitHub from './../../img/icons/gitHub.svg'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__wrapper">
                    <ul className="social">
                        <li className="social__item">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://vk.com/ivanenkomax"
                                aria-label="Перейти на страницу VK"
                            >
                                <img src={vk} alt="Иконка VK" />
                            </a>
                        </li>
                        <li className="social__item">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://instagram.com/imax_iv?igshid=YmMyMTA2M2Y="
                                aria-label="Перейти на страницу Instagram"
                            >
                                <img src={instagram} alt="Иконка Instagram" />
                            </a>
                        </li>
                        <li className="social__item">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://t.me/sonyfox"
                                aria-label="Написать в Telegram"
                            >
                                <img src={telegram} alt="Иконка Telegram" />
                            </a>
                        </li>
                        <li className="social__item">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://github.com/GitMaxm"
                                aria-label="Перейти на страницу GitHub"
                            >
                                <img src={gitHub} alt="Иконка GitHub" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>

    );
}

export default Footer;