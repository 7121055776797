import PriceTable from "../components/price/priceTable";

const Price = () => {
    return (
        <main className="section">
            <div className="container">
                <h1 className="title-1">Услуги и цены</h1>

                <PriceTable />

            </div>
        </main>
    );
}

export default Price;